<template>
  <div>
    <div
      class="d-flex align-center"
      style="gap: 10px; padding-bottom: 43px;"
    >
      <v-icon :class="{ 'dark-mode-title': isDark, 'light-mode-title': !isDark }">
        {{ icons.mdiPlaylistPlay }}
      </v-icon>
      <span :class="{ 'dark-mode-title': isDark, 'light-mode-title': !isDark }">Mídias</span>
    </div>
    <div style="width: 100%; height: 50%; display: flex; align-items: center; justify-content: center;">
      <div class="container-card">
        <div class="container-image">
          <img
            class="image"
            src="../../../../../../assets/images/svg/nexusPlayerLogo.svg"
          />

          <span
            class="system-name"
            :class="{ 'dark-mode': isDark, 'light-mode': !isDark }"
          ><span style="color: #6C63FF;">Nexus</span>Player</span>
        </div>

        <v-btn
          color="primary"
          outlined
          data-test="adm-system-button"
          class="button-access"
          @click="openNewTab"
        >
          Acessar
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { getVuetify } from '@core/utils'
import { mdiPlaylistPlay } from '@mdi/js'

export default {
  name: 'DisplayMedia',
  data() {
    return {
      icons: {
        mdiPlaylistPlay,
      },
    }
  },
  computed: {
    isDark() {
      const $vuetify = getVuetify()

      return $vuetify.theme.dark
    },
  },
  methods: {
    openNewTab() {
      const link = 'https://nexusplayer.motocasystems.com.br/NexusPlayerSplash'
      window.open(link, '_blank')
    },
  },
}
</script>

<style scoped>
.container-card {
  width: 100%;
  height: 136px;
  border-radius: 10px;
  border: 1px solid #746F90;
  background: radial-gradient(56.92% 56.92% at 99.36% 58.82%, rgba(58, 75, 255, 0.1) 0%, rgba(126, 120, 157, 0.1) 100%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.button-access {
  max-width: 111px;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-right: 67px;
  padding: 12px 20px;
}

.system-name {
  font-family: 'Bruno Ace';
  font-size: 18px;
  font-weight: 400;
  line-height: 18.15px;
}

.dark-mode {
  color: #FFF;
}

.light-mode {
  color: #5e5c61;
}

.dark-mode-title {
  color: #E7E3FC;
}

.light-mode-title {
  color: #5e5c61;
}
.container-image{
  align-items: center;
  justify-content: center;
  display: flex;
}
.image{
  width: 150px;
  height: 150px;
  padding-left: 29px;
  padding-right: 21px;
}

@media (max-width: 577px) {
  .container-card {
    flex-direction: column;
    height: auto;
    padding: 20px;
  }
  .button-access {
    margin-right: 0;
    margin-top: 40px;
    align-self: center;
  }
  .container-image{
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
  .image{
    width: 100px;
    height: 100px;
    padding-left: 0px;
    padding-right: 0px;
  }
}
</style>
